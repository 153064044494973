import axios from 'axios';

const API_URL = (typeof window !== 'undefined' && window.env?.REACT_APP_API_BASE_URL) || process.env.REACT_APP_API_BASE_URL;

class AugongApi {
  constructor() {
    console.log('初始化 API，基础 URL:', API_URL);
    if (!API_URL) {
      throw new Error('API_URL 未设置。请检查环境变量。');
    }

    this.api = axios.create({
      baseURL: API_URL,
      headers: {
        'Content-Type': 'application/json',
      },
    });

    this.cancelTokenSource = axios.CancelToken.source(); // 用于取消请求
    this.setupInterceptors();
  }

  setupInterceptors() {
    this.api.interceptors.request.use(
      (config) => {
        const token = localStorage.getItem('token');
        if (token) {
          config.headers['Authorization'] = `Bearer ${token}`; // 将 token 附加到请求头
        }
        config.cancelToken = this.cancelTokenSource.token; // 设置取消 token
        return config;
      },
      (error) => Promise.reject(error)
    );

    this.api.interceptors.response.use(
      (response) => response, // 成功的响应直接返回
      async (error) => {
        const originalRequest = error.config;
        if (error.response?.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true;
          try {
            const refreshToken = localStorage.getItem('refresh_token'); // 从 localStorage 获取 refresh token
            if (refreshToken) {
              const newToken = await this.refreshToken(refreshToken); // 调用刷新 token API
              localStorage.setItem('token', newToken); // 保存新 token
              originalRequest.headers['Authorization'] = `Bearer ${newToken}`; // 重新附加新的 token
              return this.api(originalRequest); // 重新发起被拒绝的请求
            } else {
              console.warn('没有 refresh_token，用户需要重新登录');
              this.logout(); // 没有 refresh_token 时清除登录信息
            }
          } catch (refreshError) {
            console.error('刷新 token 失败');
            this.logout(); // 刷新失败时清除登录信息
            return Promise.reject(refreshError);
          }
        }
        return Promise.reject(error);
      }
    );
  }

  async refreshToken(refreshToken) {
    const response = await this.request('post', '/refresh_token', { refresh_token: refreshToken });
    return response.access;
  }

  async request(method, url, data = null, params = null) {
    try {
      const config = {
        method,
        url,
        data,
        params,
        cancelToken: this.cancelTokenSource.token,
      };
      const response = await this.api.request(config);
      return response.data;
    } catch (error) {
      console.error(`API 请求出错: ${error.message}`);
      throw error; // 抛出异常，以便上层处理
    }
  }

  cancelPendingRequests() {
    this.cancelTokenSource.cancel('取消所有未完成的请求'); // 取消所有未完成的请求
    this.cancelTokenSource = axios.CancelToken.source(); // 重置取消令牌
  }

// 修改发送验证码的接口路径
authOperations = {
  // 注册或登录
  registerOrLogin: async (phoneNumber, verificationCode) => {
    const response = await this.request('post', '/register_or_login', {
      phone_number: phoneNumber,
      verification_code: verificationCode
    });
    if (response.access && response.refresh) {
      localStorage.setItem('token', response.access);
      localStorage.setItem('refresh_token', response.refresh);
    }
    return response;
  },

  // 发送验证码
  sendVerificationCode: (phoneNumber) =>
    this.request('post', '/send_verification_code', { phone_number: phoneNumber }),

  // 登出
  logout: () => {
    localStorage.removeItem('token');
    localStorage.removeItem('refresh_token');
  }
};


  // 获取公司折扣
  getCompanyDiscounts = (companyId) => this.request('get', `/company/discounts/${companyId}`);

  // 订单相关操作
  orderOperations = {
    getAll: (params = {}) =>
      this.request('get', '/orders', null, params),
    //接受一个 params 对象，可以包含分页和过滤参数，如 page、page_size、status、created_at__gte、created_at__lte 等
    create: (orderData) => this.request('post', '/orders', orderData),

    delete: (orderId) => {
      if (!orderId) {
        throw new Error('orderId 是必填项');
      }
      return this.request('delete', `/orders/${orderId}`);
    },

    update: (orderId, data) => {
      if (!orderId) {
        throw new Error('orderId 是必填项');
      }
      return this.request('put', `/orders/${orderId}`, data);
    },
  };

  // 订单项相关操作
  orderItemOperations = {
    getAll: (orderId, params = {}) => {
      //接受一个 params 对象，可以包含分页和过滤参数，如 page、page_size、status、created_at__gte、created_at__lte 等
      if (!orderId) {
        throw new Error('orderId 是必填项');
      }
      return this.request('get', `/orders/${orderId}/items`, null, params);
    },

    create: (orderId, itemData) => {
      if (!orderId) {
        throw new Error('orderId 是必填项');
      }
      return this.request('post', `/orders/${orderId}/items`, itemData);
    },

    update: (orderId, itemId, itemData) => {
      if (!orderId || !itemId) {
        throw new Error('orderId 和 itemId 是必填项');
      }
      return this.request('put', `/orders/${orderId}/items/${itemId}`, itemData);
    },

    delete: (orderId, itemId) => {
      if (!orderId || !itemId) {
        throw new Error('orderId 和 itemId 是必填项');
      }
      return this.request('delete', `/orders/${orderId}/items/${itemId}`);
    },
  };

  // 获取品牌和品类
  getBrandsCategories = () => this.request('get', '/brands_categories');

  // 产品相关操作
  productOperations = {
    getAll: (categoryId, page = 1, pageSize = 9, brandId = null) => {
      if (!categoryId) {
        throw new Error('category_id 是必填项');
      }
      return this.request('get', '/products', null, {
        page,
        page_size: pageSize,
        category_id: categoryId,
        brand_id: brandId,
      });
    },

    search: (query) => this.request('get', '/products/search', null, { query }),
  };
}

export default new AugongApi();
