// src/orders/OrderAdd.js

import React, { useState } from 'react';
import {
  Box,
  TextField,
  Button,
  Autocomplete,
  Typography,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  CircularProgress,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { createFilterOptions } from '@mui/material/Autocomplete';
import * as XLSX from 'xlsx';

const OrderAdd = ({
  searchProducts,
  searchResults = [],
  addProductToOrder,
  refreshOrderDetails,
  fetchProductByModel, // 新增：接收 fetchProductByModel 函数
}) => {
  const theme = useTheme();
  const [inputValue, setInputValue] = useState('');
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [importErrors, setImportErrors] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');
  const [dialogContent, setDialogContent] = useState('');
  const [loading, setLoading] = useState(false); // 新增：控制加载对话框

  const handleInputChange = (event, value) => {
    setInputValue(value);
    if (value) {
      searchProducts(value);
    } else {
      setSelectedProduct(null);
    }
  };

  const handleProductSelect = (event, value) => {
    setSelectedProduct(value);
  };

  const handleAddProduct = async () => {
    if (selectedProduct) {
      await addProductToOrder(selectedProduct.id, 1);
      setSelectedProduct(null);
      setInputValue('');
      if (refreshOrderDetails) {
        refreshOrderDetails(); // 不需要传递参数
      }
      setDialogTitle('添加成功');
      setDialogContent('商品已成功添加到订单中。');
      setDialogOpen(true);
    }
  };
  // 处理文件上传
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    setLoading(true); // 显示加载对话框

    try {
      const reader = new FileReader();

      reader.onload = async (e) => {
        try {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: 'array' });
          const worksheet = workbook.Sheets[workbook.SheetNames[0]];
          const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

          // 获取表头
          const headers = jsonData[0];
          const modelIndex = headers.indexOf('商品型号');
          const quantityIndex = headers.indexOf('数量');

          if (modelIndex === -1 || quantityIndex === -1) {
            setDialogTitle('导入失败');
            setDialogContent(
              'Excel 表格缺少必要的列，请确保包含“商品型号”和“数量”列。'
            );
            setDialogOpen(true);
            setLoading(false);
            return;
          }

          const errors = [];
          const productsToAdd = [];

          // 遍历每一行数据
          for (let i = 1; i < jsonData.length; i++) {
            const row = jsonData[i];
            const productModel = row[modelIndex];
            const quantity = row[quantityIndex];

            if (!productModel || !quantity) {
              errors.push(`第 ${i + 1} 行数据不完整，商品型号或数量缺失。`);
              continue;
            }

            // 调用 fetchProductByModel 函数，获取商品信息
            const product = await fetchProductByModel(productModel);

            if (!product) {
              errors.push(
                `第 ${i + 1} 行的商品型号 “${productModel}” 未找到。`
              );
            } else {
              productsToAdd.push({
                productId: product.id,
                quantity: Number(quantity),
              });
            }
          }

          if (errors.length > 0) {
            setImportErrors(errors);
            setDialogTitle('导入失败');
            setDialogContent('部分商品未找到，请修改后重新导入。');
            setDialogOpen(true);
          } else {
            // 所有商品型号都找到，开始添加到订单
            for (const item of productsToAdd) {
              await addProductToOrder(item.productId, item.quantity);
            }
            setImportErrors([]);
            setDialogTitle('导入成功');
            setDialogContent('所有商品已成功添加到订单中。');
            setDialogOpen(true);
            if (refreshOrderDetails) {
              refreshOrderDetails();
            }
          }
        } catch (error) {
          console.error('处理 Excel 数据出错:', error);
          setDialogTitle('处理文件出错');
          setDialogContent(`处理 Excel 数据出错：${error.message}`);
          setDialogOpen(true);
        } finally {
          setLoading(false); // 处理完成，关闭加载对话框
        }
      };

      reader.onerror = (e) => {
        console.error('读取文件时出错:', e);
        setDialogTitle('读取文件出错');
        setDialogContent(`读取文件时出错：${e.target.error.message}`);
        setDialogOpen(true);
        setLoading(false);
      };

      reader.readAsArrayBuffer(file);
    } catch (error) {
      console.error('读取 Excel 文件出错:', error);
      setDialogTitle('读取文件出错');
      setDialogContent(`读取 Excel 文件出错：${error.message}`);
      setDialogOpen(true);
      setLoading(false);
    }
  };

  // 创建自定义过滤函数
  const filterOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option) =>
      `${option.name} ${option.brand} ${option.category}`,
  });

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleDownloadTemplate = () => {
    // 创建一个工作表
    const worksheet = XLSX.utils.aoa_to_sheet([['商品型号', '数量']]);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, '模板');
  
    // 生成文件并触发下载
    XLSX.writeFile(workbook, '导入商品格式.xlsx');
  };

  return (
    <>
      <Divider sx={{ my: 2 }} />
      <Autocomplete
        options={searchResults}
        filterOptions={filterOptions}
        getOptionLabel={(option) =>
          `${option.name} - ${option.brand} - ${option.category}`
        }
        noOptionsText="未找到匹配的商品"
        inputValue={inputValue}
        onInputChange={handleInputChange}
        value={selectedProduct}
        onChange={handleProductSelect}
        renderOption={(props, option) => (
          <Box
            component="li"
            {...props}
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              py: 1.5,
              px: 2,
              '&:hover': {
                backgroundColor: theme.palette.action.hover,
              },
              width: '100%',
            }}
          >
            <Box sx={{ flex: 1 }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 'medium' }}>
                {option.name}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {option.brand} | {option.category}
              </Typography>
            </Box>
            <Typography
              variant="subtitle1"
              color="primary.main"
              sx={{ marginLeft: 'auto' }}
            >
              ¥{option.price}
            </Typography>
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label="请输入商品型号"
            variant="outlined"
            fullWidth
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: theme.palette.divider,
                },
                '&:hover fieldset': {
                  borderColor: theme.palette.primary.main,
                },
                '&.Mui-focused fieldset': {
                  borderColor: theme.palette.primary.main,
                },
              },
            }}
          />
        )}
        sx={{ mb: 3 }}
      />
      <Button
        variant="contained"
        color="primary"
        onClick={handleAddProduct}
        disabled={!selectedProduct}
        sx={{
          width: '100%',
          py: 1.5,
          textTransform: 'none',
          fontWeight: 'medium',
          borderRadius: 2,
          boxShadow: 2,
          '&:hover': {
            boxShadow: 4,
          },
        }}
      >
        加入订单
      </Button>

      {/* 导入 Excel 部分 */}
      <Divider sx={{ my: 4 }} />
      <Box sx={{ textAlign: 'center', mb: 2 }}>
        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>批量导入商品</Typography>
        <a href="#" onClick={handleDownloadTemplate}>点击下载Excel式</a>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 2,
        }}
      >
        <Button
          variant="contained"
          component="label"
          startIcon={<CloudUploadIcon />}
          sx={{ borderRadius: 2, boxShadow: 2 }}
        >
          选择 Excel 文件
          <input
            type="file"
            accept=".xlsx, .xls"
            hidden
            onChange={handleFileUpload}
          />
        </Button>
      </Box>
      {importErrors.length > 0 && (
        <Box sx={{ mt: 2 }}>
          <Typography color="error" variant="h6">
            导入错误：
          </Typography>
          {importErrors.map((error, index) => (
            <Typography color="error" key={index}>
              {error}
            </Typography>
          ))}
        </Box>
      )}

      {/* 对话框组件 */}
      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {dialogContent}
            {importErrors.length > 0 && (
              <>
                <Typography variant="h6" sx={{ mt: 2 }}>
                  详细错误信息：
                </Typography>
                {importErrors.map((error, index) => (
                  <Typography color="error" key={index}>
                    {error}
                  </Typography>
                ))}
              </>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            确定
          </Button>
        </DialogActions>
      </Dialog>

      {/* 加载动画对话框 */}
      <Dialog open={loading}>
        <DialogContent sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <CircularProgress />
          <DialogContentText>正在检查表格数据......</DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default OrderAdd;
