//components/products/ProductList.js
import React from 'react';
import {
  Paper,
  Typography,
  CircularProgress,
  Box,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Button,
  useTheme
} from '@mui/material';
import InventoryIcon from '@mui/icons-material/Inventory';
import useUser from '../../hooks/useUser'; // 引入 useUser hook

const ProductList = ({
  products,
  loading,
  error,
  currentPage,
  totalPages,
  changePage
}) => {
  const theme = useTheme();
  const { user, authChecked } = useUser(); // 获取用户登录状态和认证检查状态

  const handleFirstPage = () => {
    if (currentPage !== 1) {
      changePage(1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      changePage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      changePage(currentPage + 1);
    }
  };

  const handleLastPage = () => {
    if (currentPage !== totalPages) {
      changePage(totalPages);
    }
  };

  if (loading || !authChecked) { 
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="200px">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return <Typography color="error" sx={{ margin: '20px', fontWeight: 'bold' }}>{error}</Typography>;
  }

  if (!products || products.length === 0) {
    return <Typography sx={{ padding: '20%', marginLeft: '25%', color: theme.palette.text.primary }}>请先选择一个品类</Typography>;
  }

  return (
    <>
      <Box display="flex" alignItems="center" mb={2} mt={3}>
        <InventoryIcon sx={{ mr: 1.5, color: '#34495e', fontSize: 36 }} />
        <Typography variant="h4" color="#34495e" fontWeight={700}>
          产品目录
        </Typography>
      </Box>

      <TableContainer component={Paper} elevation={0} sx={{ backgroundColor: 'transparent', borderRadius: '8px', overflow: 'hidden' }}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold', textAlign: 'center' }}>品类名</TableCell>
              <TableCell sx={{ fontWeight: 'bold', textAlign: 'center' }}>品牌名</TableCell>
              <TableCell sx={{ fontWeight: 'bold', textAlign: 'center' }}>型号</TableCell>
              <TableCell sx={{ fontWeight: 'bold', textAlign: 'center' }}>单价</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {products.map((product) => (
              <TableRow key={product.id} sx={{ backgroundColor: '#f8f8f9', '&:hover': { backgroundColor: '#dcdde1' } }}>
                <TableCell component="th" scope="row" sx={{ textAlign: 'center' }}>
                  {product.category}
                </TableCell>
                <TableCell sx={{ textAlign: 'center' }}>{product.brand}</TableCell>
                <TableCell sx={{ textAlign: 'center' }}>{product.name}</TableCell>

                {/* 价格显示逻辑 */}
                <TableCell sx={{ textAlign: 'center' }}>
                  {user 
                    ? (product.price != null ? `￥${product.price.toFixed(2)}` : '请联系客服查看')
                    : '登录后显示'}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {totalPages > 1 && (
        <Box sx={{ mt: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Button
            onClick={handleFirstPage}
            disabled={currentPage === 1}
            sx={{ minWidth: 'auto', marginRight: 1 }}
          >
            |&lt;
          </Button>
          <Button
            onClick={handlePreviousPage}
            disabled={currentPage === 1}
            sx={{ minWidth: 'auto', marginRight: 1 }}
          >
            &lt;
          </Button>

          <Typography sx={{ marginX: 2 }}>
            {currentPage} / {totalPages}
          </Typography>

          <Button
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
            sx={{ minWidth: 'auto', marginLeft: 1 }}
          >
            &gt;
          </Button>
          <Button
            onClick={handleLastPage}
            disabled={currentPage === totalPages}
            sx={{ minWidth: 'auto', marginLeft: 1 }}
          >
            &gt;|
          </Button>
        </Box>
      )}
    </>
  );
};

export default ProductList;
