import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import theme from './styles/theme';  
import ProductsPage from './pages/ProductsPage';
import CheckoutPage from './pages/CheckoutPage';
import OrdersPage from './pages/OrdersPage';
import LoginPage from './pages/LoginPage';
import OrderSuccessPage from './pages/OrderSuccessPage';
import Header from './components/common/Header';
import Footer from './components/common/Footer';
import OrderDetailsPage from './pages/OrderDetailsPage';
import { UserProvider } from './contexts/UserContext';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';
import './App.css';  // 引入 CSS 文件

function App() {
  return (
    <Router>
      <ThemeProvider theme={theme}>  
        <CssBaseline />
        <UserProvider>
          <Box
            className="App"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              minHeight: '100vh', // 使外层容器至少与视口等高
            }}
          >
            <Header />
            <Box
              sx={{
                flexGrow: 1,
                overflowY: 'auto', // 恢复滚动
                backgroundColor: 'rgba(255, 255, 255, 0.85)',
              }}
            >
              <Box sx={{ padding: '0 10%' }}>
                <Routes>
                  <Route path="/login" element={<LoginPage />} />
                  <Route path="/products" element={<ProductsPage />} />
                  <Route path="/checkout" element={<ProtectedRoute element={CheckoutPage} />} />
                  <Route path="/orders" element={<ProtectedRoute element={OrdersPage} />} />
                  <Route path="/orders/:orderId" element={<ProtectedRoute element={OrderDetailsPage} />} />
                  <Route path="/order-success" element={<ProtectedRoute element={OrderSuccessPage} />} />
                  <Route path="/" element={<Navigate to="/products" replace />} />
                </Routes>
              </Box>
            </Box>
            <Footer />
          </Box>
        </UserProvider>
      </ThemeProvider>
    </Router>
  );
}

export default App;
