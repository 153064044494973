// src/pages/OrderDetailsPage.js

import React, { useEffect, useCallback, useState } from 'react';
import {
  Box,
  CircularProgress,
  Typography,
  Container,
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import OrderDetails from '../components/orders/OrderDetails';
import useOrders from '../hooks/useOrders';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';

const ConfirmDialog = ({ open, onClose, onConfirm, title, content }) => (
  <Dialog open={open} onClose={onClose}>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      <DialogContentText>{content}</DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="secondary">
        取消
      </Button>
      <Button onClick={onConfirm} color="primary">
        确定
      </Button>
    </DialogActions>
  </Dialog>
);

const OrderDetailsPage = () => {
  const { orderId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { order: passedOrder } = location.state || {};

  const {
    fetchOrderDetails,
    selectedOrder,
    addOrderItem,
    updateOrderItemQuantity,
    deleteOrderItem,
    updateOrder,
    deleteOrder,
    searchProducts,
    searchResults,
    fetchOrderItems,
    loading,
    error,
    fetchProductByModel,
  } = useOrders();

  const [isSubmitDialogOpen, setSubmitDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);

  // 获取订单详情
  useEffect(() => {
    if (orderId) {
      fetchOrderDetails(orderId, passedOrder);
    }
  }, [orderId, fetchOrderDetails]);

  const handleSubmitOrder = useCallback(
    async (orderId) => {
      try {
        await updateOrder(orderId, { status: 'processing' });
        navigate('/order-success');
      } catch (error) {
        console.error('提交订单时出错', error);
      }
    },
    [updateOrder, navigate]
  );

  const handleDeleteOrder = useCallback(
    async (orderId) => {
      try {
        await deleteOrder(orderId);
        navigate('/orders');
      } catch (error) {
        console.error('删除订单时出错', error);
      }
    },
    [deleteOrder, navigate]
  );

  if (loading || (!passedOrder && !selectedOrder)) {
    return (
      <Container maxWidth="lg">
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="60vh"
        >
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  if (error) {
    return (
      <Container maxWidth="lg">
        <Box my={4}>
          <Typography variant="h6" color="error" align="center">
            {error}
          </Typography>
        </Box>
      </Container>
    );
  }

  return (
    <Box sx={{ marginTop: 4 }}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Typography variant="h4">订单详情</Typography>
        {selectedOrder?.status === 'opened' && (
          <Box>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setSubmitDialogOpen(true)}
              startIcon={<ShoppingCartCheckoutIcon />}
              sx={{ mr: 2 }}
            >
              提交订单
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => setDeleteDialogOpen(true)}
              startIcon={<DeleteOutlineIcon />}
            >
              删除订单
            </Button>
          </Box>
        )}
      </Grid>

      {/* 订单详情 */}
      <Box
        sx={{ mt: 1, p: 4, border: '1px solid', borderColor: 'divider' }}
      >
      <OrderDetails
        order={selectedOrder}
        onAddOrderItem={addOrderItem}
        onUpdateOrderItemQuantity={updateOrderItemQuantity}
        onDeleteOrderItem={deleteOrderItem}
        onUpdateOrder={updateOrder}
        searchProducts={searchProducts}
        searchResults={searchResults}
        refreshOrderDetails={() => fetchOrderDetails(orderId)} 
        fetchOrderItems={fetchOrderItems}
        fetchProductByModel={fetchProductByModel} 
      />
      </Box>

      {/* 提交确认对话框 */}
      <ConfirmDialog
        open={isSubmitDialogOpen}
        onClose={() => setSubmitDialogOpen(false)}
        onConfirm={() => {
          handleSubmitOrder(selectedOrder.id);
          setSubmitDialogOpen(false);
        }}
        title="确认提交订单"
        content="请仔细检查商品信息，您确定要提交订单吗？"
      />

      {/* 删除确认对话框 */}
      <ConfirmDialog
        open={isDeleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        onConfirm={() => {
          handleDeleteOrder(selectedOrder.id);
          setDeleteDialogOpen(false);
        }}
        title="确认删除订单" style={{ fontWeight: 900, color: 'black' }}
        content="删除操作不可逆，您确定要删除此订单吗？"
      />
    </Box>
  );
};

export default OrderDetailsPage;
