// src/orders/OrderDetails.js

import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Divider,
  IconButton,
  TextField,
  Grid,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import OrderAdd from './OrderAdd';
import OrderItems from './OrderItems';

const STATUS_OPTIONS = [
  { value: 'all', label: '全部' },
  { value: 'opened', label: '已开启' },
  { value: 'processing', label: '待处理' },
  { value: 'finished', label: '已完成' },
  { value: 'cancelled', label: '已取消' },
];

const OrderDetails = ({
  order,
  onAddOrderItem,
  onUpdateOrderItemQuantity,
  onDeleteOrderItem,
  onUpdateOrder,
  searchProducts,
  searchResults,
  refreshOrderDetails: fetchOrderDetails,
  fetchOrderItems,
  fetchProductByModel,
}) => {
  const [isEditingName, setIsEditingName] = useState(false);
  const [editedName, setEditedName] = useState(order?.name || '');
  const [totalPrice, setTotalPrice] = useState(0);

  // 确认 order 数据是否加载
  useEffect(() => {
    console.log('订单详情：', order);
  }, [order]);

  if (!order) {
    return <Typography>加载订单信息中...</Typography>;
  }

  const handleUpdateName = () => {
    if (editedName !== order.name) {
      onUpdateOrder(order.id, { name: editedName });
    }
    setIsEditingName(false);
  };

  return (
    <Box>
      <Grid container spacing={2}>
        {/* 左侧内容 */}
        <Grid item xs={12} md={8}>
          {/* 商品列表 */}
          <OrderItems
              orderId={order.id}
              orderStatus={order.status}
              fetchOrderItems={fetchOrderItems}
              onUpdateItemQuantity={onUpdateOrderItemQuantity}
              onDeleteItem={onDeleteOrderItem}
              onTotalPriceChange={(total) => setTotalPrice(total)}
            />
          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            <Typography variant="h6" sx={{ mr: 1 }}>
              订单总价:
            </Typography>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              ¥{totalPrice.toFixed(2)}
            </Typography>
          </Box>
        </Grid>

        {/* 右侧内容 */}
        <Grid item xs={12} md={4}>
          {/* 订单基础信息 */}
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={12} sm={12}>
              <Box display="flex" alignItems="center">
                <Typography
                  variant="subtitle1"
                  sx={{ fontWeight: 'bold', color: 'text.primary', mr: 1 }}
                >
                  订单名称：
                </Typography>
                {isEditingName ? (
                  <>
                    <TextField
                      variant="outlined"
                      value={editedName}
                      onChange={(e) => setEditedName(e.target.value)}
                      size="small"
                      sx={{ mr: 1 }}
                    />
                    <IconButton
                      onClick={handleUpdateName}
                      color="primary"
                      sx={{ mr: 1 }}
                    >
                      <CheckIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => setIsEditingName(false)}
                      color="error"
                    >
                      <CloseIcon />
                    </IconButton>
                  </>
                ) : (
                  <>
                    <Typography variant="body1" sx={{ fontSize: '1.1rem' }}>
                      {order.name}
                    </Typography>
                    {order.status === 'opened' && (
                      <IconButton
                        onClick={() => setIsEditingName(true)}
                        sx={{ ml: 1 }}
                      >
                        <EditIcon fontSize="small" />
                      </IconButton>
                    )}
                  </>
                )}
              </Box>
            </Grid>

            <Grid item xs={12} sm={12}>
              <Typography
                variant="subtitle1"
                sx={{ fontWeight: 'bold', color: 'text.primary' }}
              >
                订单状态：
                <Typography
                  component="span"
                  variant="body1"
                  sx={{ ml: 1, fontSize: '1.1rem', color: 'success.main' }}
                >
                  {
                    STATUS_OPTIONS.find(
                      (option) => option.value === order.status
                    )?.label || '未知状态'
                  }
                </Typography>
              </Typography>
            </Grid>

            <Grid item xs={12} sm={12}>
              <Typography
                variant="subtitle1"
                sx={{ fontWeight: 'bold', color: 'text.primary' }}
              >
                创建时间：
                <Typography
                  component="span"
                  variant="body1"
                  sx={{ ml: 1, fontSize: '1.1rem' }}
                >
                  {new Date(order.created_at).toLocaleString()}
                </Typography>
              </Typography>
            </Grid>

            <Grid item xs={12} sm={12}>
              <Typography
                variant="subtitle1"
                sx={{ fontWeight: 'bold', color: 'text.primary' }}
              >
                更新时间：
                <Typography
                  component="span"
                  variant="body1"
                  sx={{ ml: 1, fontSize: '1.1rem' }}
                >
                  {new Date(order.updated_at).toLocaleString()}
                </Typography>
              </Typography>
            </Grid>
          </Grid>

          {/* 仅在状态为 opened 时显示添加商品功能 */}
          {order.status === 'opened' && (
            <OrderAdd
              searchProducts={searchProducts}
              searchResults={searchResults}
              addProductToOrder={(productId, quantity) =>
                onAddOrderItem(order.id, productId, quantity)
              }
              refreshOrderDetails={fetchOrderDetails}
              fetchProductByModel={fetchProductByModel}
            />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default OrderDetails;
