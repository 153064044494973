// contexts/UserContext.js
import React, { createContext, useContext } from 'react';
import useUser from '../hooks/useUser';

const UserContext = createContext();

let userFunctions = {};

export const UserProvider = ({ children }) => {
  const userData = useUser();
  userFunctions = userData; // 暴露给外部使用
  return <UserContext.Provider value={userData}>{children}</UserContext.Provider>;
};

export const useUserContext = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUserContext must be used within a UserProvider');
  }
  return context;
};

export const getUserFunctions = () => userFunctions;

export { UserContext };
