// src/hooks/useOrders.js

import { useState, useCallback, useEffect } from 'react';
import augongApi from '../api/augongApi';

const useOrders = () => {
  const [orders, setOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(7); // 每页显示的订单数量
  const [totalPages, setTotalPages] = useState(1);
  const [totalOrders, setTotalOrders] = useState(0); // 总订单数

  const [filters, setFilters] = useState({
    name: '',
    status: 'all',
    startDate: null,
    endDate: null,
  });

  // 新增状态统计
  const [statusCounts, setStatusCounts] = useState({
    all: 0,
    opened: 0,
    processing: 0,
    finished: 0,
    cancelled: 0,
  });

  const resetErrorAndLoading = () => {
    setError(null);
    setLoading(false);
  };

  const handleError = (err, message) => {
    console.error(message, err);
    setError(message);
    setLoading(false);
  };

  // 获取订单列表
  const fetchOrders = useCallback(async () => {
    setLoading(true);
    try {
      // 构建参数对象
      const params = {
        page: currentPage,
        page_size: rowsPerPage,
      };
      // 添加过滤参数
      if (filters.status && filters.status !== 'all') {
        params.status = filters.status;
      }
      if (filters.name) {
        params.name = filters.name;
      }
      if (filters.startDate) {
        params.created_at__gte = filters.startDate.toISOString().split('T')[0];
      }
      if (filters.endDate) {
        params.created_at__lte = filters.endDate.toISOString().split('T')[0];
      }

      // 调用 API 获取订单列表
      const response = await augongApi.orderOperations.getAll(params);
      setOrders(response.results || []);
      setTotalOrders(response.count || 0);
      setTotalPages(Math.ceil((response.count || 0) / rowsPerPage));
    } catch (err) {
      handleError(err, '获取订单列表失败，请稍后再试。');
    } finally {
      setLoading(false);
    }
  }, [currentPage, rowsPerPage, filters]);

  // 获取每个状态的订单数量
  const fetchStatusCounts = useCallback(async () => {
    try {
      const counts = {
        all: 0,
        opened: 0,
        processing: 0,
        finished: 0,
        cancelled: 0,
      };
      // 定义所有状态
      const statuses = ['opened', 'processing', 'finished', 'cancelled'];

      // 获取所有订单的总数（不带过滤条件）
      const totalResponse = await augongApi.orderOperations.getAll({
        page: 1,
        page_size: 1,
      });
      counts['all'] = totalResponse.count || 0;

      // 获取每个状态的订单数量
      await Promise.all(
        statuses.map(async (status) => {
          const params = {
            status: status,
            page: 1,
            page_size: 1,
          };
          const response = await augongApi.orderOperations.getAll(params);
          counts[status] = response.count || 0;
        })
      );

      setStatusCounts(counts);
    } catch (err) {
      console.error('获取订单状态数量失败', err);
    }
  }, []);

  // 当分页或过滤条件变化时，调用 fetchOrders
  useEffect(() => {
    fetchOrders();
  }, [fetchOrders]);

  // 在组件加载时获取状态统计数据
  useEffect(() => {
    fetchStatusCounts();
  }, [fetchStatusCounts]);

  // 创建新订单
  const createOrder = useCallback(
    async (orderData) => {
      try {
        const newOrder = await augongApi.orderOperations.create(orderData);
        // 创建订单后，重新获取订单列表和状态统计，并重置到第一页
        setCurrentPage(1);
        await fetchOrders();
        await fetchStatusCounts(); // 更新状态统计
        return newOrder; // 返回新创建的订单
      } catch (err) {
        handleError(err, '创建订单失败，请稍后再试。');
        throw err; // 抛出错误以便上层处理
      }
    },
    [fetchOrders, fetchStatusCounts]
  );

  // 获取订单详情
  const fetchOrderDetails = useCallback(async (orderId, initialData) => {
    try {
      setLoading(true);
      if (initialData) {
        setSelectedOrder(initialData);
      } else {
        const data = await augongApi.orderOperations.getAll({ id: orderId });
        if (data && data.results && data.results.length > 0) {
          setSelectedOrder(data.results[0]);
        } else {
          handleError('未找到订单详情', new Error('Order not found'));
        }
      }
      setLoading(false);
    } catch (error) {
      handleError('获取订单详情失败，请稍后再试。', error);
    }
  }, []);

  // 更新订单（名称或状态）
  const updateOrder = useCallback(
    async (orderId, data) => {
      try {
        const updatedOrder = await augongApi.orderOperations.update(
          orderId,
          data
        );
        setSelectedOrder(updatedOrder); // 更新当前选中的订单
        // 同步更新订单列表中的订单信息
        setOrders((prevOrders) =>
          prevOrders.map((order) =>
            order.id === orderId ? updatedOrder : order
          )
        );
        await fetchStatusCounts(); // 更新状态统计
      } catch (err) {
        handleError(err, '更新订单失败，请稍后再试。');
      }
    },
    [fetchStatusCounts]
  );

  // 删除订单
  const deleteOrder = useCallback(
    async (orderId) => {
      setLoading(true);
      try {
        await augongApi.orderOperations.delete(orderId);
        // 删除后，重新获取订单列表和状态统计
        await fetchOrders();
        await fetchStatusCounts();
      } catch (err) {
        handleError(err, '删除订单失败，请稍后再试。');
      } finally {
        setLoading(false);
      }
    },
    [fetchOrders, fetchStatusCounts]
  );

  // 添加商品到订单
  const addOrderItem = useCallback(async (orderId, productId, quantity = 1) => {
    try {
      await augongApi.orderItemOperations.create(orderId, {
        product_id: productId,
        quantity: quantity,
      });
      // 不需要更新 selectedOrderItems，因为订单项由 OrderItems 组件自行获取
    } catch (err) {
      handleError(err, '添加商品失败，请稍后再试。');
    }
  }, []);
  // 定义 fetchProductByModel 函数
  const fetchProductByModel = useCallback(async (model) => {
    try {
      // 调用搜索 API，传递产品型号作为查询参数
      const data = await augongApi.productOperations.search(model);
  
      // 检查返回的数据是否有效
      if (data && data.length > 0) {
        // 返回找到的产品
        return data[0];
      } else {
        // 未找到产品
        return null;
      }
    } catch (error) {
      handleError('获取商品信息失败，请稍后再试。', error);
      return null;
    }
  }, []);

  // 更新订单项数量
  const updateOrderItemQuantity = useCallback(
    async (orderId, itemId, newQuantity) => {
      if (newQuantity < 1) return;
      try {
        await augongApi.orderItemOperations.update(orderId, itemId, {
          quantity: newQuantity,
        });
        // 不需要更新 selectedOrderItems
      } catch (err) {
        handleError(err, '更新商品数量失败，请稍后再试。');
        throw err;
      }
    },
    []
  );

  // 删除商品项
  const deleteOrderItem = useCallback(async (orderId, itemId) => {
    try {
      await augongApi.orderItemOperations.delete(orderId, itemId);
      // 不需要更新 selectedOrderItems
    } catch (err) {
      handleError(err, '删除商品失败，请稍后再试。');
      throw err;
    }
  }, []);

  // 搜索商品
  const searchProducts = useCallback(async (query) => {
    try {
      const results = await augongApi.productOperations.search(query);
      setSearchResults(results || []);
      return results; // 返回搜索结果
    } catch (err) {
      handleError(err, '搜索商品失败，请稍后再试。');
      return []; // 返回空数组，避免 undefined
    }
  }, []);

  // 获取订单项，支持分页
  const fetchOrderItems = useCallback(
    async (orderId, page = 1, rowsPerPage = 10) => {
      try {
        const params = {
          page,
          page_size: rowsPerPage,
        };
        const itemsResponse = await augongApi.orderItemOperations.getAll(
          orderId,
          params
        );
        const orderItems = itemsResponse.results || [];
        const totalCount = itemsResponse.count || 0; // 总订单项数量
        const totalPrice = itemsResponse.total_price || 0; // 总价（假设后端返回了 total_price）
        return {
          items: orderItems,
          totalCount,
          totalPrice,
        };
      } catch (err) {
        handleError(err, '获取订单项失败，请稍后再试。');
        return null;
      }
    },
    []
  );

  return {
    orders,
    totalOrders,
    totalPages,
    selectedOrder,
    searchResults,
    loading,
    error,
    currentPage,
    setCurrentPage,
    filters,
    setFilters,
    statusCounts,
    createOrder,
    fetchOrders,
    fetchOrderDetails,
    updateOrder,
    deleteOrder,
    addOrderItem,
    updateOrderItemQuantity,
    deleteOrderItem,
    fetchProductByModel,
    searchProducts,
    fetchOrderItems,
    resetErrorAndLoading,
  };
};

export default useOrders;
