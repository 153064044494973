import React from 'react';
import { 
  Box, 
  Typography, 
  Button, 
  CircularProgress, 
  Grid, 
  Paper, 
  Card, 
  CardContent, 
  CardMedia, 
  Container
} from '@mui/material';
import Slider from 'react-slick';
import useProducts from '../hooks/useProducts';
import ProductCategory from '../components/products/ProductCategory';
import ProductList from '../components/products/ProductList';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

const carouselImages = [
  {
    src: require('../assets/Parts1.jpg'),
    title: '品质保证',
    description: '提供高品质的工业用品，确保您的生产顺利进行。',
  },
  {
    src: require('../assets/Parts2.jpg'),
    title: '专业服务',
    description: '我们的专业团队随时为您提供支持。',
  },
  {
    src: require('../assets/Parts3.jpg'),
    title: '快速交付',
    description: '快速的物流网络，确保及时交付。',
  },
  {
    src: require('../assets/Parts4.jpg'),
    title: '一站式采购',
    description: '满足您所有的工业用品需求。',
  },
  {
    src: require('../assets/Parts5.jpg'),
    title: '客户至上',
    description: '您的满意是我们的追求。',
  },
];

const featuredProducts = [
  {
    id: 1,
    name: '产品包装',
    image: require('../assets/product1.jpg'),
  },
  {
    id: 2,
    name: '欧美数控刀具',
    image: require('../assets/product2.jpg'),
  },
  {
    id: 3,
    name: '试刀服务',
    image: require('../assets/product3.jpg'),
  },
];

const testimonials = [
  {
    id: 1,
    name: '张先生',
    position: '制造业采购经理',
    comment: 'AUGONG的服务非常专业，产品质量有保障，值得信赖！',
  },
  {
    id: 2,
    name: '李女士',
    position: '工程项目负责人',
    comment: '一站式采购真的方便了很多，节省了我们的时间和成本。',
  },
];

const ProductsPage = () => {
  const { 
    categories, 
    products, 
    loading, 
    error, 
    currentPage, 
    totalPages, 
    changePage,
    selectBrand,
    selectCategory,
    currentBrandId,
    selectedCategoryId
  } = useProducts();

  const handleSelectCategory = (categoryId) => {
    selectCategory(categoryId);
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  if (loading && !products.length) return <CircularProgress />;
  if (error) return <Typography color="error">{error}</Typography>;
  if (!categories || categories.length === 0) {
    return <Typography>暂无可用品类</Typography>;
  }

  return (
    <Box sx={{ backgroundColor: 'transparent' }}>
      

      {/* Carousel */}
      <Box sx={{ position: 'relative', mt: 5 }}>
        <Slider {...settings}>
          {carouselImages.map((item, index) => (
            <Box
              key={index}
              sx={{
                position: 'relative',
                backgroundImage: `url(${item.src})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                height: { xs: '200px', md: '500px' },
              }}
            >
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  backgroundColor: 'rgba(0, 0, 0, 0.4)',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: '#fff',
                }}
              >
                <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                  {item.title}
                </Typography>
                <Typography variant="h6" sx={{ mt: 2 }}>
                  {item.description}
                </Typography>
              </Box>
            </Box>
          ))}
        </Slider>
      </Box>

      {/* Featured Products */}
      <Box sx={{ mt: 10, px: { xs: 2, md: 10 } }}>
        <Typography variant="h4" sx={{ fontWeight: 'bold', textAlign: 'center', mb: 5 }}>
          特色产品
        </Typography>
        <Grid container spacing={4}>
          {featuredProducts.map((product) => (
            <Grid item xs={12} md={4} key={product.id}>
              <Card sx={{ boxShadow: 3 }}>
                <CardMedia
                  component="img"
                  height="200"
                  image={product.image}
                  alt={product.name}
                />
                <CardContent>
                  <Typography variant="h6" sx={{ fontWeight: 'bold', textAlign: 'center' }}>
                    {product.name}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>

      {/* Product Category */}
      <Box sx={{ mt: 10 }}>
        <ProductCategory 
          categories={categories} 
          onSelectCategory={handleSelectCategory}
          onSelectBrand={selectBrand}
          selectedCategoryId={selectedCategoryId}
          selectedBrandId={currentBrandId}
        />
      </Box>

      {/* Product List */}
      <Box sx={{ mt: 5 }}>
        <ProductList 
          products={products}
          loading={loading}
          error={error}
          currentPage={currentPage}
          totalPages={totalPages}
          changePage={changePage}
        />
      </Box>

      {/* Testimonials */}
      <Box sx={{ mt: 10, backgroundColor: '#f1f1f1', py: 10 }}>
        <Typography variant="h4" sx={{ fontWeight: 'bold', textAlign: 'center', mb: 5 }}>
          客户评价
        </Typography>
        <Grid container spacing={4} sx={{ px: { xs: 2, md: 10 } }}>
          {testimonials.map((testimonial) => (
            <Grid item xs={12} md={6} key={testimonial.id}>
              <Paper sx={{ p: 5, boxShadow: 3 }}>
                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                  {testimonial.name} - {testimonial.position}
                </Typography>
                <Typography variant="body1" sx={{ mt: 2, color: '#555' }}>
                  "{testimonial.comment}"
                </Typography>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Box>

      {/* Footer */}
      <Box sx={{ backgroundColor: '#2c3e50', py: 5, mt: 10 }}>
        <Container maxWidth="lg">
          <Grid container spacing={4}>
            <Grid item xs={12} md={4}>
              <Typography variant="h6" sx={{ color: '#fff', fontWeight: 'bold', mb: 2 }}>
                关于我们
              </Typography>
              <Typography variant="body2" sx={{ color: '#ccc' }}>
              公司成立于2018年07月16日，公司位于上海市松江区茸梅路518号1幢11层1116室，是一家综合性数控刀具批发商，年销售额1.5亿
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="h6" sx={{ color: '#fff', fontWeight: 'bold', mb: 2 }}>
                联系方式
              </Typography>
              <Typography variant="body2" sx={{ color: '#ccc' }}>
                电话：400-123-4567<br/>
                邮箱：info@zkh360.com<br/>
                地址：上海市松江区茸梅路518号1幢11层1116室
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="h6" sx={{ color: '#fff', fontWeight: 'bold', mb: 2 }}>
                关注我们
              </Typography>
              <Typography variant="body2" sx={{ color: '#ccc' }}>
                微信公众号：AAAA工业超市<br/>
                新浪微博：AAAA工业超市
              </Typography>
            </Grid>
          </Grid>
          <Typography variant="body2" sx={{ color: '#ccc', textAlign: 'center', mt: 5 }}>
            © 2023 AAAA超市 版权所有
          </Typography>
        </Container>
      </Box>
    </Box>
  );
};

export default ProductsPage;
