// src/ProtectedRoute.js

import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useUserContext } from './contexts/UserContext';
import { CircularProgress, Box } from '@mui/material';

function ProtectedRoute({ element: Component, allowGuest = false, ...rest }) {
  const { user, authChecked } = useUserContext();
  const location = useLocation();

  if (!authChecked) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (!user && !allowGuest) {
    // 保存用户尝试访问的路径，以便登录后重定向
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return <Component {...rest} />;
}

export default ProtectedRoute;
