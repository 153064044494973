import React, { useEffect } from 'react';
import {
  Box,
  Typography,
  Grid,
  Chip,
  Button,
  styled,
  Grow
} from '@mui/material';
import FactoryIcon from '@mui/icons-material/Factory';
import StorefrontIcon from '@mui/icons-material/Storefront';

// 简约风格的 CategoryChip
const CategoryChip = styled(Chip)(({ selected }) => ({
  backgroundColor: selected ? '#2c3e50' : '#f8f8f9',
  color: selected ? '#ffffff' : '#34495e',
  borderRadius: '20px',
  fontSize: '0.9rem',
  fontWeight: 500,
  padding: '8px 12px',
  border: `1px solid ${selected ? '#e74c3c' : 'transparent'}`,
  '&:hover': {
    backgroundColor: selected ? '#34495e' : '#dcdde1',
    borderColor: '#e74c3c',
  },
  margin: '4px',
  transition: 'background-color 0.3s ease, border-color 0.3s ease',
}));

// 简约风格的 BrandButton
const BrandButton = styled(Button)(({ selected }) => ({
  backgroundColor: selected ? '#2c3e50' : '#f8f8f9',
  color: selected ? '#ffffff' : '#34495e',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '6px 12px',
  borderRadius: '20px',
  fontSize: '0.85rem',
  fontWeight: 500,
  border: `1px solid ${selected ? '#e74c3c' : 'transparent'}`,
  '&:hover': {
    backgroundColor: selected ? '#34495e' : '#dcdde1',
    borderColor: '#e74c3c',
  },
  margin: '4px',
  transition: 'background-color 0.3s ease, border-color 0.3s ease',
  textTransform: 'none',
}));

const ProductCategory = ({ 
  categories, 
  onSelectCategory, 
  onSelectBrand, 
  selectedCategoryId, 
  selectedBrandId 
}) => {

  // 默认选中第一个品类
  useEffect(() => {
    if (!selectedCategoryId && categories.length > 0) {
      onSelectCategory(categories[0].id);
    }
  }, [selectedCategoryId, categories, onSelectCategory]);

  // 默认选中第一个品牌
  useEffect(() => {
    if (selectedCategoryId && !selectedBrandId) {
      const selectedCategory = categories.find((c) => c.id === selectedCategoryId);
      if (selectedCategory && selectedCategory.brands.length > 0) {
        onSelectBrand(selectedCategory.brands[0].id);
      }
    }
  }, [selectedCategoryId, selectedBrandId, categories, onSelectBrand]);

  const handleCategoryClick = (categoryId) => {
    onSelectCategory(categoryId);
  };

  const handleBrandClick = (brandId) => {
    onSelectBrand(brandId);
  };

  return (
    <>
      {/* 顶部标题部分 */}
      <Box display="flex" alignItems="center" mb={2} mt={3}>
        <FactoryIcon sx={{ mr: 1.5, color: '#34495e', fontSize: 36 }} />
        <Typography variant="h4" color="#34495e" fontWeight={700}>
          品类和品牌
        </Typography>
      </Box>

      <Grid container spacing={2}>
        {/* 品类区域 */}
        <Grid item xs={12}>
          <Typography variant="h6" color="#34495e" fontWeight={600} gutterBottom>
            品类
          </Typography>
          <Box display="flex" flexWrap="wrap">
            {categories.map((category, index) => (
              <Grow
                key={category.id}
                in={true}
                style={{ transformOrigin: '0 0 0' }}
                timeout={500 + index * 100}
              >
                <CategoryChip
                  label={category.name}
                  onClick={() => handleCategoryClick(category.id)}
                  selected={selectedCategoryId === category.id}
                />
              </Grow>
            ))}
          </Box>
        </Grid>

        {/* 品牌区域 */}
        <Grid item xs={12}>
          <Typography variant="h6" color="#34495e" fontWeight={600} gutterBottom>
            品牌
          </Typography>
          <Box display="flex" flexWrap="wrap">
            {selectedCategoryId &&
              categories
                .find((c) => c.id === selectedCategoryId)
                ?.brands.map((brand, index) => (
                  <Grow
                    key={brand.id}
                    in={true}
                    style={{ transformOrigin: '0 0 0' }}
                    timeout={500 + index * 100}
                  >
                    <BrandButton
                      selected={selectedBrandId === brand.id}
                      onClick={() => handleBrandClick(brand.id)}
                    >
                      <StorefrontIcon sx={{ mb: 0, fontSize: 20, color: selectedBrandId === brand.id ? '#ffffff' : '#34495e' }} />
                      <Typography variant="caption" fontSize="0.85rem">
                        {brand.name}
                      </Typography>
                    </BrandButton>
                  </Grow>
                ))}
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default ProductCategory;
