import React, { useContext } from 'react';
import { AppBar, Toolbar, IconButton, Typography, Button, Breadcrumbs, Link as MuiLink, Tooltip } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ListAltIcon from '@mui/icons-material/ListAlt';
import { Link, useLocation } from 'react-router-dom';
import { UserContext } from '../../contexts/UserContext';
import logo from './logo mini.png'; // 导入logo

const Header = () => {
  const { user, logout } = useContext(UserContext);
  const location = useLocation();
  const pathnames = location.pathname.split('/').filter((x) => x);

  const breadcrumbs = [{ path: '/products', label: '首页' }];
  
  if (pathnames[0] === 'orders') {
    breadcrumbs.push({ path: '/orders', label: '订单' });
    if (pathnames.length > 1) {
      breadcrumbs.push({ path: `/orders/${pathnames[1]}`, label: '订单详情' });
    }
  }

  return (
    <AppBar position="static" >
      <Toolbar>
        <IconButton
          component={Link}
          to="/products"
          edge="start"
          sx={{
            marginLeft: '1rem',
            marginRight: '1rem',
            width: 35,
            height: 35,
            backgroundColor: 'white',
            borderRadius: '50%',
            padding: '5px',
          }}
        >
          <img 
            src={logo} 
            alt="logo" 
            style={{ 
              width: '80%', 
              height: '80%' 
            }} 
          />
        </IconButton>

        <Breadcrumbs sx={{
          flexGrow: 1,
          color: 'white',
          fontSize: '1.1rem',
          letterSpacing: '0.5px',
        }}>
          {breadcrumbs.map((crumb, index) => (
            index === breadcrumbs.length - 1 && crumb.label === '订单详情' ? (
              <Typography key={crumb.path} color="white" fontWeight="bold">
                {crumb.label}
              </Typography>
            ) : (
              <MuiLink
                key={crumb.path}
                color="white"
                component={Link}
                to={crumb.path}
                underline="hover"
                sx={{
                  '&:hover': { color: 'rgba(255, 255, 255, 0.8)' },
                }}
              >
                {crumb.label}
              </MuiLink>
            )
          ))}
        </Breadcrumbs>

        {user && (
          <>
            <Tooltip title="我的订单">
              <IconButton color="inherit" component={Link} to="/orders" sx={{ '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.1)' } }}>
                <ListAltIcon />
              </IconButton>
            </Tooltip>
            <Button
              color="inherit"
              onClick={logout}
              sx={{
                fontSize: '1rem',
                textTransform: 'none',
                '&:hover': {
                  color: 'rgba(255, 255, 255, 0.8)',
                },
              }}
            >
              登出
            </Button>
          </>
        )}

        {!user && (
          <Button
            color="inherit"
            component={Link}
            to="/login"
            sx={{
              fontSize: '1rem',
              textTransform: 'none',
              '&:hover': {
                color: 'rgba(255, 255, 255, 0.8)',
              },
            }}
          >
            登录
          </Button>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Header;
