// hooks/useProducts.js
import { useState, useEffect, useCallback } from 'react';
import augongApi from '../api/augongApi';

const useProducts = () => {
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [currentBrandId, setCurrentBrandId] = useState(null);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null); // 新增 selectedCategoryId 状态
  const pageSize = 20;

  // 获取品类和品牌数据
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        setLoading(true);
        const response = await augongApi.getBrandsCategories();
        if (Array.isArray(response) && response.length > 0) {
          setCategories(response);
        } else {
          setCategories([]);
        }
      } catch (err) {
        setError('获取品类失败。请稍后再试。');
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();
  }, []);

  // 选择品牌
  const selectBrand = useCallback((brandId) => {
    setCurrentBrandId(brandId);
    setCurrentPage(1);
  }, []);

  // 选择品类
  const selectCategory = useCallback((categoryId) => {
    setSelectedCategoryId(categoryId);
    setCurrentBrandId(null); // 重置品牌选择
    setCurrentPage(1); // 重置页码
  }, []);

  // 切换页面
  const changePage = useCallback((newPage) => {
    setCurrentPage(newPage);
  }, []);

  // 根据选中的品类和品牌获取产品
  useEffect(() => {
    if (!selectedCategoryId) {
      setProducts([]);
      setTotalPages(1);
      return;
    }

    const fetchProducts = async () => {
      try {
        setLoading(true);
        const response = await augongApi.productOperations.getAll(selectedCategoryId, currentPage, pageSize, currentBrandId);
        console.log('API 响应：', response);

        if (response && Array.isArray(response.results)) {
          setProducts(response.results);
          const total = Math.ceil(response.count / pageSize); // 根据返回的总产品数计算总页数
          setTotalPages(total);
        } else {
          setProducts([]);
          setTotalPages(1);
        }
      } catch (err) {
        console.error('获取产品失败：', err);
        setError('获取产品失败。请稍后再试。');
        setProducts([]);
        setTotalPages(1);
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, [selectedCategoryId, currentBrandId, currentPage]);

  return { 
    categories, 
    products, 
    loading, 
    error, 
    currentPage, 
    totalPages, 
    changePage,
    selectBrand,
    selectCategory, // 返回 selectCategory 方法
    currentBrandId,
    selectedCategoryId, // 返回 selectedCategoryId 状态
  };
};

export default useProducts;
