//styles/theme.js
import { createTheme } from '@mui/material/styles';

const visuallyHidden = {
  border: 0,
  margin: -1,
  padding: 0,
  width: '1px',
  height: '1px',
  overflow: 'hidden',
  position: 'absolute',
  whiteSpace: 'nowrap',
  clip: 'rect(0 0 0 0)',
};

const theme = createTheme({
  palette: {
    primary: {
      main: '#2c3e50', // 优化主题色
      light: '#34495e',
      dark: '#1a242f',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#FF3B30',
      light: '#FF6B61',
      dark: '#C41E3A',
      contrastText: '#FFFFFF',
    },
    accent: {
      main: '#E74C3C', // 添加点缀色
    },
    background: {
      default: '#F2F2F7',
      paper: '#FFFFFF',
    },
    text: {
      primary: '#1D1D1F',
      secondary: '#86868B',
    },
    error: { main: '#FF3B30' },
    warning: { main: '#FF9500' },
    info: { main: '#007AFF' },
    success: { main: '#34C759' },
  },
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"SF Pro Display"',
      '"SF Pro Text"',
      'Roboto',
      'Arial',
      'sans-serif',
    ].join(','),
    h1: { fontSize: '3rem', fontWeight: 700 },
    h2: { fontSize: '2.5rem', fontWeight: 700 },
    h3: { fontSize: '2rem', fontWeight: 600 },
    h4: { fontSize: '1.75rem', fontWeight: 600 },
    h5: { fontSize: '1.5rem', fontWeight: 500 },
    h6: { fontSize: '1.25rem', fontWeight: 500 },
    body1: { fontSize: '1rem' },
    body2: { fontSize: '0.875rem' },
  },
  shape: {
    borderRadius: 8, // 增加圆角
  },
  spacing: 8,
  components: {
    MuiTable: {
      styleOverrides: {
        root: {
          borderCollapse: 'separate',
          boxShadow: 'none', // 移除表格阴影
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          backgroundColor: '#F2F2F7',
          color: '#F2F2F7',
          fontWeight: 700,
          fontSize: '0.9rem', // 增加表头字体大小
          padding: '15px', // 增加表头内边距
          backgroundColor: '#2c3e50',
          textAlign: 'center', // 表头内容水平居中对齐
          verticalAlign: 'middle', // 表头内容垂直居中对齐
        },
        body: {
          fontSize: '0.875rem',
          color: '#1D1D1F',
          padding: '9px',
          border: '1px solid transparent', // 默认情况下预留透明边框
          textAlign: 'center', // 表格内容水平居中对齐
          verticalAlign: 'middle', // 表格内容垂直居中对齐
          '&:hover': {
            border: `1px solid #E74C3C`, // 鼠标hover时的边框效果
          },
        },
      },
    },
    // 添加自定义组件的样式
    TableEmptyRows: {
      styleOverrides: {
        root: ({ height, emptyRows }) => ({
          ...(height && { height: height * emptyRows }),
        }),
      },
    },
    TableHeadCustom: {
      styleOverrides: {
        root: {
          '& .MuiCheckbox-root': {
            indeterminate: (numSelected, rowCount) => !!numSelected && numSelected < rowCount,
            checked: (numSelected, rowCount) => !!rowCount && numSelected === rowCount,
          },
          '& .MuiTableSortLabel-root': {
            '& .MuiBox-root': visuallyHidden,
          },
        },
      },
    },
    TableNoData: {
      styleOverrides: {
        root: {
          '& .MuiTableCell-root': {
            padding: 0,
            '& .MuiBox-root': {
              paddingY: 10,
            },
          },
        },
      },
    },
    TablePaginationCustom: {
      styleOverrides: {
        root: {
          '& .MuiTablePagination-root': {
            borderTopColor: 'transparent',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          },
          '& .MuiFormControlLabel-root': {
            paddingLeft: 2,
            paddingY: 1.5,
            top: 0,
            position: 'absolute',
          },
          '& .MuiTablePagination-displayedRows': {
            margin: '0 8px',
            display: 'none', // 隐藏显示的行数
          },
          '& .MuiTablePagination-selectRoot': {
            marginRight: '8px',
          },
          '& .MuiTablePagination-toolbar': {
            display: 'flex',
            justifyContent: 'center',
          },
          '& .MuiTablePagination-page': {
            margin: '0 8px',
          },
        },
      },
    },
    TableSelectedAction: {
      styleOverrides: {
        root: {
          paddingLeft: 1,
          paddingRight: 2,
          top: 0,
          left: 0,
          width: '100%',
          zIndex: 9,
          height: 58,
          position: 'absolute',
          backgroundColor: 'primary.lighter',
          '&.dense': {
            height: 38,
          },
        },
      },
    },
    TableSkeleton: {
      styleOverrides: {
        root: {
          '& .MuiSkeleton-root': {
            borderRadius: 1.5,
            width: 48,
            height: 48,
            flexShrink: 0,
            '&:last-child': {
              width: 1,
              height: 12,
            },
          },
        },
      },
    },
    // 添加 Label 样式覆盖
    MuiLabel: {
      styleOverrides: {
        root: ({ theme, ownerState: { color, variant } }) => {
          const defaultColor = {
            ...(color === 'default' && {
              ...(variant === 'filled' && {
                color: theme.palette.common.white,
                backgroundColor: theme.palette.text.primary,
              }),
              ...(variant === 'outlined' && {
                backgroundColor: 'transparent',
                color: theme.palette.text.primary,
                border: `2px solid ${theme.palette.text.primary}`,
              }),
              ...(variant === 'soft' && {
                color: theme.palette.text.secondary,
                backgroundColor: theme.palette.grey[500],
              }),
              ...(variant === 'inverted' && {
                color: theme.palette.grey[800],
                backgroundColor: theme.palette.grey[300],
              }),
            }),
          };

          const styleColors = {
            ...(color !== 'default' && {
              ...(variant === 'filled' && {
                color: theme.palette[color].contrastText,
                backgroundColor: theme.palette[color].main,
              }),
              ...(variant === 'outlined' && {
                backgroundColor: 'transparent',
                color: theme.palette[color].main,
                border: `2px solid ${theme.palette[color].main}`,
              }),
              ...(variant === 'soft' && {
                color: theme.palette[color].dark,
                backgroundColor: theme.palette[color].light,
              }),
              ...(variant === 'inverted' && {
                color: theme.palette[color].darker,
                backgroundColor: theme.palette[color].lighter,
              }),
            }),
          };

          return {
            height: 24,
            minWidth: 24,
            lineHeight: 0,
            cursor: 'default',
            alignItems: 'center',
            whiteSpace: 'nowrap',
            display: 'inline-flex',
            justifyContent: 'center',
            padding: theme.spacing(0, 0.75),
            fontSize: theme.typography.pxToRem(12),
            fontWeight: theme.typography.fontWeightBold,
            borderRadius: theme.shape.borderRadius * 0.75,
            transition: theme.transitions.create('all', {
              duration: theme.transitions.duration.shorter,
            }),
            ...defaultColor,
            ...styleColors,
          };
        },
      },
    },
    // 添加图表相关样式
    MuiChart: {
      styleOverrides: {
        root: {
          width: '100%',
          height: '100%',
          borderRadius: '12px',
          boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
          backgroundColor: '#FFFFFF',  // 图表背景色
          padding: '16px',  // 图表的间距
        },
      },
    },
    apexcharts: {
      styleOverrides: {
        tooltip: {
          borderRadius: '10px',
          boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
          backdropFilter: 'blur(6px)',
          backgroundColor: 'rgba(255, 255, 255, 0.9)',  // 工具提示背景色
        },
        xaxisTooltip: {
          borderRadius: '10px',
          borderColor: 'transparent',
          backdropFilter: 'blur(6px)',
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
        },
        legend: {
          padding: 0,
          marker: {
            marginRight: '6px',
          },
          text: {
            marginLeft: '0px',
            paddingLeft: '0px',
          },
        },
      },
    },
  },
});

export default theme;
