// src/components/orders/OrderList.js

import React from 'react';
import {
  Box,
  Paper,
  Typography,
  CircularProgress,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Button,
  Chip,
  IconButton,
  Tooltip,
  TextField,
  Stack,
  Tab,
  Tabs,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/DriveFileRenameOutline';
import useOrders from '../../hooks/useOrders';
import { useTheme } from '@mui/material/styles';

const STATUS_OPTIONS = [
  { value: 'all', label: '全部' },
  { value: 'opened', label: '已开启' },
  { value: 'processing', label: '待处理' },
  { value: 'finished', label: '已完成' },
  { value: 'cancelled', label: '已取消' },
];

const OrderList = () => {
  const navigate = useNavigate();
  const theme = useTheme();

  const {
    orders,
    loading,
    error,
    totalOrders,
    totalPages,
    currentPage,
    setCurrentPage,
    filters,
    setFilters,
    statusCounts, // 获取状态统计数据
  } = useOrders();

  const dateError =
    filters.startDate && filters.endDate && filters.startDate > filters.endDate;

  const handleOrderClick = (order) => {
    navigate(`/orders/${order.id}`, { state: { order } });
  };

  const handleFirstPage = () => {
    if (currentPage !== 1) {
      setCurrentPage(1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleLastPage = () => {
    if (currentPage !== totalPages) {
      setCurrentPage(totalPages);
    }
  };

  const handleFilterStatus = (event, newValue) => {
    setFilters({ ...filters, status: newValue });
    setCurrentPage(1); // 过滤条件变化时，重置页码
  };

  const handleFilterStartDate = (event) => {
    setFilters({
      ...filters,
      startDate: event.target.value ? new Date(event.target.value) : null,
    });
    setCurrentPage(1);
  };

  const handleFilterEndDate = (event) => {
    setFilters({
      ...filters,
      endDate: event.target.value ? new Date(event.target.value) : null,
    });
    setCurrentPage(1);
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'opened':
        return 'success';
      case 'processing':
        return 'warning';
      case 'finished':
        return 'info';
      case 'cancelled':
        return 'error';
      default:
        return 'default';
    }
  };

  if (loading && orders.length === 0) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="200px">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Typography color="error" sx={{ margin: '20px', fontWeight: 'bold' }}>
        {error}
      </Typography>
    );
  }

  return (
    <>
      {/* 状态筛选部分，使用 Tabs */}
      <Tabs value={filters.status} onChange={handleFilterStatus} sx={{ mb: 2 }}>
        {STATUS_OPTIONS.map((tab) => (
          <Tab
            key={tab.value}
            value={tab.value}
            label={tab.label}
            icon={
              <Chip
                label={
                  statusCounts[tab.value] !== undefined
                    ? statusCounts[tab.value]
                    : 0
                }
                color={getStatusColor(tab.value)}
                size="small"
                variant={tab.value === filters.status ? 'filled' : 'outlined'}
              />
            }
            iconPosition="end"
          />
        ))}
      </Tabs>

      {/* 其他筛选部分 */}
      <Stack spacing={2} direction={{ xs: 'column', md: 'row' }} alignItems="center" mb={2}>
        <TextField
          label="开始日期"
          type="date"
          value={filters.startDate ? filters.startDate.toISOString().split('T')[0] : ''}
          onChange={handleFilterStartDate}
          InputLabelProps={{
            shrink: true,
          }}
          sx={{ maxWidth: 200 }}
        />

        <TextField
          label="结束日期"
          type="date"
          value={filters.endDate ? filters.endDate.toISOString().split('T')[0] : ''}
          onChange={handleFilterEndDate}
          error={dateError}
          helperText={dateError ? '结束日期必须晚于开始日期' : null}
          InputLabelProps={{
            shrink: true,
          }}
          sx={{ maxWidth: 200 }}
        />
      </Stack>

      {/* 已应用的筛选条件 */}
      {(filters.status !== 'all' || filters.startDate || filters.endDate) && (
        <Stack direction="row" flexWrap="wrap" alignItems="center" sx={{ mb: 2 }}>
          {filters.status !== 'all' && (
            <Chip
              label={`状态: ${
                STATUS_OPTIONS.find((option) => option.value === filters.status)?.label
              }`}
              onDelete={() => {
                setFilters({ ...filters, status: 'all' });
                setCurrentPage(1);
              }}
              sx={{ m: 0.5 }}
            />
          )}
          {(filters.startDate || filters.endDate) && (
            <Chip
              label={`日期: ${
                filters.startDate ? filters.startDate.toLocaleDateString() : '不限'
              } - ${filters.endDate ? filters.endDate.toLocaleDateString() : '不限'}`}
              onDelete={() => {
                setFilters({ ...filters, startDate: null, endDate: null });
                setCurrentPage(1);
              }}
              sx={{ m: 0.5 }}
            />
          )}
        </Stack>
      )}

      {/* 订单列表表格 */}
      <TableContainer
        component={Paper}
        elevation={0}
        sx={{ backgroundColor: 'transparent', borderRadius: '8px', overflow: 'hidden' }}
      >
        <Table sx={{ minWidth: 700 }} aria-label="订单表格">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold', textAlign: 'center' }}>订单ID</TableCell>
              <TableCell sx={{ fontWeight: 'bold', textAlign: 'center' }}>订单名称</TableCell>
              <TableCell sx={{ fontWeight: 'bold', textAlign: 'center' }}>订单创建时间</TableCell>
              <TableCell sx={{ fontWeight: 'bold', textAlign: 'center' }}>订单更新时间</TableCell>
              <TableCell sx={{ fontWeight: 'bold', textAlign: 'center' }}>订单状态</TableCell>
              <TableCell sx={{ fontWeight: 'bold', textAlign: 'center' }}>操作</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orders.length > 0 ? (
              orders.map((order) => (
                <TableRow
                  key={order.id}
                  sx={{
                    backgroundColor: '#f8f8f9',
                    '&:hover': { backgroundColor: '#dcdde1' },
                  }}
                >
                  <TableCell sx={{ textAlign: 'center' }}>{order.id}</TableCell>
                  <TableCell sx={{ textAlign: 'center' }}>{order.name}</TableCell>
                  <TableCell sx={{ textAlign: 'center' }}>
                    {new Date(order.created_at).toLocaleString()}
                  </TableCell>
                  <TableCell sx={{ textAlign: 'center' }}>
                    {new Date(order.updated_at).toLocaleString()}
                  </TableCell>
                  <TableCell sx={{ textAlign: 'center' }}>
                    <Chip
                      label={
                        STATUS_OPTIONS.find((option) => option.value === order.status)?.label
                      }
                      color={getStatusColor(order.status)}
                      size="small"
                    />
                  </TableCell>
                  <TableCell sx={{ textAlign: 'center' }}>
                    <Tooltip title={order.status !== 'opened' ? '查看订单' : '编辑订单'}>
                      <IconButton
                        onClick={() => handleOrderClick(order)}
                        sx={{ color: theme.palette.primary.main }}
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  <Typography variant="body1">没有找到订单</Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* 分页按钮 */}
      {totalPages > 1 && (
        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Button
            onClick={handleFirstPage}
            disabled={currentPage === 1}
            sx={{ minWidth: 'auto', marginRight: 1 }}
          >
            |&lt;
          </Button>
          <Button
            onClick={handlePreviousPage}
            disabled={currentPage === 1}
            sx={{ minWidth: 'auto', marginRight: 1 }}
          >
            &lt;
          </Button>

          <Typography sx={{ marginX: 2 }}>
            {currentPage} / {totalPages}
          </Typography>

          <Button
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
            sx={{ minWidth: 'auto', marginLeft: 1 }}
          >
            &gt;
          </Button>
          <Button
            onClick={handleLastPage}
            disabled={currentPage === totalPages}
            sx={{ minWidth: 'auto', marginLeft: 1 }}
          >
            &gt;|
          </Button>
        </Box>
      )}
    </>
  );
};

export default React.memo(OrderList);
