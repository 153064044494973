import React from 'react';
import { 
  Box, Typography, Container, Paper, ThemeProvider, Button, Divider, Chip
} from '@mui/material';
import { styled, keyframes } from '@mui/material/styles';  // 导入 keyframes
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useNavigate } from 'react-router-dom';

import customTheme from '../styles/theme';  // 重命名导入的 theme

// 使用 keyframes 定义动画
const pulse = keyframes`
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
`;

const AnimatedIcon = styled(CheckCircleOutlineIcon)(({ theme }) => ({
  fontSize: 64,
  color: theme.palette.primary.main,
  animation: `${pulse} 2s infinite`,
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(6),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(3),
  borderRadius: 16,
  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
})); 

export default function OrderSuccessPage() {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate('/orders');
  };

  return (
    <ThemeProvider theme={customTheme}>  {/* 使用重命名的 customTheme */}
      <Container maxWidth="sm" sx={{ mt: 8 }}>
        <StyledPaper elevation={0}>
          <AnimatedIcon />
          <Typography variant="h4" align="center" gutterBottom>
            订单已确认
          </Typography>
          <Chip 
            label="订单号：12345" 
            color="primary" 
            sx={{ fontSize: '1rem', py: 1, px: 2 }}
          />
          <Typography variant="body1" align="center" color="text.secondary">
            感谢您的购买！我们将尽快处理您的订单。
          </Typography>
          <Divider sx={{ width: '100%', my: 2 }} />
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="body2" color="text.secondary">
              空运预计送达时间
            </Typography>
            <Typography variant="body1" sx={{ fontWeight: 'medium' }}>
              3天
            </Typography>
          </Box>
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="body2" color="text.secondary">
              陆运预计送达时间
            </Typography>
            <Typography variant="body1" sx={{ fontWeight: 'medium' }}>
              15天
            </Typography>
          </Box>
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="body2" color="text.secondary">
              海运预计送达时间
            </Typography>
            <Typography variant="body1" sx={{ fontWeight: 'medium' }}>
              25-45天
            </Typography>
          </Box>
          <Button 
            variant="contained" 
            color="primary" 
            endIcon={<ArrowForwardIcon />}
            fullWidth
            sx={{ mt: 2 }}
            onClick={handleGoBack}
          >
            返回订单页面
          </Button>
        </StyledPaper>
      </Container>
    </ThemeProvider>
  );
}
