import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  Paper,
  Typography,
  Alert,
  Box,
  TextField,
  Button,
  InputAdornment,
} from '@mui/material';

import { UserContext } from '../contexts/UserContext';
import augongApi from '../api/augongApi';

function LoginPage() {
  const navigate = useNavigate();
  const { login } = useContext(UserContext);

  const [phoneNumber, setPhoneNumber] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [isCodeSent, setIsCodeSent] = useState(false);

  const handleSendCode = async () => {
    setErrorMessage('');
    if (!phoneNumber || !/^\d{11}$/.test(phoneNumber)) {
      setErrorMessage('请输入有效的11位手机号码');
      return;
    }

    try {
      setLoading(true);
      await augongApi.authOperations.sendVerificationCode(phoneNumber);
      setIsCodeSent(true);
    } catch (error) {
      if (error.response && error.response.data && error.response.data.detail) {
        setErrorMessage(error.response.data.detail);
      } else {
        setErrorMessage('验证码发送失败，请稍后再试');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage('');

    if (!phoneNumber || !verificationCode) {
      setErrorMessage('请填写完整的手机号和验证码');
      return;
    }

    try {
      setLoading(true);
      const response = await augongApi.authOperations.registerOrLogin(
        phoneNumber,
        verificationCode
      );

      if (response.access && response.refresh) {
        login(response.access, response.refresh);
        navigate('/products');
      } else {
        setErrorMessage('登录失败，请重试');
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.detail) {
        setErrorMessage(error.response.data.detail);
      } else {
        setErrorMessage('网络错误，请稍后再试');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Paper elevation={3} sx={{ mt: 8, p: 4 }}>
        <Typography component="h1" variant="h5" align="center">
          登录 / 注册
        </Typography>

        {errorMessage && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {errorMessage}
          </Alert>
        )}

        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
          <Box gap={3} display="flex" flexDirection="column">
            <TextField
              required
              fullWidth
              label="手机号码"
              id="phoneNumber"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              disabled={loading}
              InputLabelProps={{ shrink: true }}
            />

            <TextField
              required
              fullWidth
              label="验证码"
              id="verificationCode"
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
              disabled={loading}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Button
                      variant="text"
                      onClick={handleSendCode}
                      disabled={loading || isCodeSent}
                    >
                      {isCodeSent ? '已发送' : '发送验证码'}
                    </Button>
                  </InputAdornment>
                ),
              }}
            />

            <Button
              fullWidth
              variant="contained"
              type="submit"
              disabled={!phoneNumber || !verificationCode || loading}
              sx={{ mt: 3 }}
            >
              {loading ? '登录中...' : '登录'}
            </Button>
          </Box>
        </Box>
      </Paper>
    </Container>
  );
}

export default LoginPage;
