import React, { useState } from 'react';
import {
  Box,
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
} from '@mui/material';
import useOrders from '../hooks/useOrders';
import OrderList from '../components/orders/OrderList';
import { useNavigate } from 'react-router-dom';

const OrdersPage = () => {
  const { createOrder, updateOrder } = useOrders();
  const navigate = useNavigate();

  const [openDialog, setOpenDialog] = useState(false);
  const [orderName, setOrderName] = useState('未命名订单');

  const handleCreateOrderClick = () => {
    setOrderName('未命名订单');
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleCreateOrder = async () => {
    try {
      const newOrder = await createOrder({ name: '未命名订单' });

      if (orderName !== '未命名订单') {
        await updateOrder(newOrder.id, { name: orderName });
        newOrder.name = orderName;
      }

      navigate(`/orders/${newOrder.id}`, { state: { order: newOrder } });
      setOpenDialog(false);
    } catch (error) {
      console.error('创建订单失败：', error);
    }
  };

  return (
    <Box
      sx={{
        marginTop: 4,
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h4">您的订单</Typography>
        <Button variant="contained" color="primary" onClick={handleCreateOrderClick}>
          创建新订单
        </Button>
      </Box>

      {/* 包装 OrderList */}
      <Box sx={{ flexGrow: 1 }}>
        <OrderList />
      </Box>

      {/* 创建订单的对话框 */}
      <Dialog open={openDialog} onClose={handleDialogClose}>
        <DialogTitle style={{ fontWeight: 'bold', color: 'black' }}>新建一个订单</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="订单名称"
            type="text"
            fullWidth
            value={orderName}
            onChange={(e) => setOrderName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>取消</Button>
          <Button onClick={handleCreateOrder} variant="contained" color="primary">
            确定
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default OrdersPage;
