// hooks/useUser.js
import { useState, useEffect, useCallback } from 'react';
import augongApi from '../api/augongApi';
import { jwtDecode } from 'jwt-decode';

const TOKEN_KEY = 'token';
const REFRESH_TOKEN_KEY = 'refresh_token';

const useUser = () => {
  const [user, setUser] = useState(() => {
    const token = localStorage.getItem(TOKEN_KEY);
    const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);
    if (token && refreshToken) {
      const decoded = jwtDecode(token);
      return { token, refreshToken, ...decoded };
    }
    return null;
  });
  const [authChecked, setAuthChecked] = useState(false);

  const login = useCallback((accessToken, refreshToken) => {
    localStorage.setItem(TOKEN_KEY, accessToken);
    localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
    const decoded = jwtDecode(accessToken);
    setUser({ token: accessToken, refreshToken, ...decoded });
  }, []);

  const logout = useCallback(() => {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(REFRESH_TOKEN_KEY);
    setUser(null);
  }, []);

  const refreshAuthToken = useCallback(async () => {
    const currentRefreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);
    if (!currentRefreshToken) {
      throw new Error('No refresh token available');
    }
    try {
      const response = await augongApi.authOperations.refreshToken(currentRefreshToken);
      login(response.access, response.refresh);
      return response.access;
    } catch (error) {
      console.error('Failed to refresh token:', error);
      logout();
      throw error;
    }
  }, [login, logout]);

  const checkAuthStatus = useCallback(async () => {
    const token = localStorage.getItem(TOKEN_KEY);
    if (token) {
      const decoded = jwtDecode(token);
      const currentTime = Date.now() / 1000;
      if (decoded.exp < currentTime) {
        // Token 已过期，尝试刷新
        try {
          await refreshAuthToken();
        } catch {
          logout();
        }
      } else {
        setUser({ token, refreshToken: localStorage.getItem(REFRESH_TOKEN_KEY), ...decoded });
      }
    } else {
      logout();
    }
    setAuthChecked(true);
  }, [logout, refreshAuthToken]);

  useEffect(() => {
    checkAuthStatus();
  }, [checkAuthStatus]);

  return { user, login, logout, authChecked, refreshAuthToken };
};

export default useUser;
