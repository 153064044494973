// src/orders/OrderItems.js

import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Typography,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  TableFooter,
  TablePagination,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import DeleteIcon from '@mui/icons-material/Delete';

const OrderItems = ({
  orderId,
  orderStatus,
  fetchOrderItems,
  onUpdateItemQuantity,
  onDeleteItem,
  onTotalPriceChange,
}) => {
  const [editingItemId, setEditingItemId] = useState(null);
  const [tempQuantity, setTempQuantity] = useState({});

  // 分页状态
  const [page, setPage] = useState(0); // 当前页，从0开始
  const [rowsPerPage, setRowsPerPage] = useState(5); // 每页显示的行数
  const [items, setItems] = useState([]); // 当前页的订单项
  const [totalItems, setTotalItems] = useState(0); // 总订单项数量
  const [loading, setLoading] = useState(false);

  // 更新总价的函数
  const updateTotalPrice = useCallback(
    (updatedItems) => {
      const total = updatedItems.reduce(
        (sum, item) => sum + item.curr_unit_price * item.quantity,
        0
      );
      if (onTotalPriceChange) {
        onTotalPriceChange(total);
      }
    },
    [onTotalPriceChange]
  );

  // 获取订单项
  const loadItems = useCallback(async () => {
    setLoading(true);
    const result = await fetchOrderItems(orderId, page + 1, rowsPerPage);
    if (result) {
      setItems(result.items);
      setTotalItems(result.totalCount);

      // 前端计算总价
      updateTotalPrice(result.items);
    }
    setLoading(false);
  }, [fetchOrderItems, orderId, page, rowsPerPage, updateTotalPrice]);

  useEffect(() => {
    loadItems();
  }, [page, rowsPerPage]);

  const handleEditQuantity = (itemId, currentQuantity) => {
    setEditingItemId(itemId);
    setTempQuantity({ ...tempQuantity, [itemId]: currentQuantity });
  };

  const handleQuantityChange = (itemId, newQuantity) => {
    if (newQuantity >= 1) {
      setTempQuantity({ ...tempQuantity, [itemId]: newQuantity });
    }
  };

  const handleBlur = async (itemId) => {
    const newQuantity = tempQuantity[itemId];
    if (newQuantity !== undefined) {
      await onUpdateItemQuantity(orderId, itemId, newQuantity);
      // 更新本地状态并重新计算总价
      setItems((prevItems) => {
        const updatedItems = prevItems.map((item) =>
          item.id === itemId ? { ...item, quantity: newQuantity } : item
        );
        updateTotalPrice(updatedItems);
        return updatedItems;
      });
    }
    setEditingItemId(null);
  };

  const handleDecreaseQuantity = async (itemId, currentQuantity) => {
    if (currentQuantity <= 1) return;
    const newQuantity = currentQuantity - 1;
    await onUpdateItemQuantity(orderId, itemId, newQuantity);
    // 更新本地状态并重新计算总价
    setItems((prevItems) => {
      const updatedItems = prevItems.map((item) =>
        item.id === itemId ? { ...item, quantity: newQuantity } : item
      );
      updateTotalPrice(updatedItems);
      return updatedItems;
    });
  };

  const handleIncreaseQuantity = async (itemId, currentQuantity) => {
    const newQuantity = currentQuantity + 1;
    await onUpdateItemQuantity(orderId, itemId, newQuantity);
    // 更新本地状态并重新计算总价
    setItems((prevItems) => {
      const updatedItems = prevItems.map((item) =>
        item.id === itemId ? { ...item, quantity: newQuantity } : item
      );
      updateTotalPrice(updatedItems);
      return updatedItems;
    });
  };

  const handleDeleteItem = async (itemId) => {
    await onDeleteItem(orderId, itemId);

    // 更新 totalItems
    const newTotalItems = totalItems - 1;
    const totalPages = Math.ceil(newTotalItems / rowsPerPage);
    let newPage = page;

    // 如果当前页超出总页数，调整页码
    if (page + 1 > totalPages && page > 0) {
      newPage = page - 1;
      setPage(newPage);
    }

    // 重新加载数据
    await loadItems();
  };

  // 处理分页更改
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // 处理每页行数更改
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // 重置页码
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="200px"
      >
        <Typography>加载中...</Typography>
      </Box>
    );
  }

  if (!items || items.length === 0) {
    return <Typography>该订单暂无商品。</Typography>;
  }

  return (
    <TableContainer  component={Paper}
    elevation={0}
    sx={{ backgroundColor: 'white', borderRadius: '8px', overflow: 'hidden' }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>商品名</TableCell>
            <TableCell align="right">单价</TableCell>
            <TableCell align="center">数量</TableCell>
            <TableCell align="right">总价</TableCell>
            {/* 仅在订单状态为 'opened' 时显示“操作”列 */}
            {orderStatus === 'opened' && (
              <TableCell align="center">操作</TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((item) => (
            <TableRow key={item.id} hover>
              <TableCell>
                <Typography variant="subtitle1" fontWeight="medium">
                  {item.product_name}
                </Typography>
              </TableCell>
              <TableCell align="right">
                ¥{item.curr_unit_price.toFixed(2)}
              </TableCell>
              <TableCell align="center">
                <Box display="flex" alignItems="center" justifyContent="center">
                  {orderStatus === 'opened' ? (
                    editingItemId === item.id ? (
                      <TextField
                        value={tempQuantity[item.id]}
                        onChange={(e) =>
                          handleQuantityChange(
                            item.id,
                            parseInt(e.target.value) || 1
                          )
                        }
                        onBlur={() => handleBlur(item.id)}
                        inputProps={{ min: 1, type: 'number' }}
                        autoFocus
                        size="small"
                        sx={{ width: 60 }}
                      />
                    ) : (
                      <>
                        <IconButton
                          onClick={() =>
                            handleDecreaseQuantity(item.id, item.quantity)
                          }
                          disabled={item.quantity <= 1}
                          size="small"
                        >
                          <RemoveIcon />
                        </IconButton>
                        <Typography
                          onClick={() =>
                            handleEditQuantity(item.id, item.quantity)
                          }
                          sx={{
                            cursor: 'pointer',
                            mx: 1,
                            minWidth: 30,
                            textAlign: 'center',
                          }}
                        >
                          {item.quantity}
                        </Typography>
                        <IconButton
                          onClick={() =>
                            handleIncreaseQuantity(item.id, item.quantity)
                          }
                          size="small"
                        >
                          <AddIcon />
                        </IconButton>
                      </>
                    )
                  ) : (
                    <Typography>{item.quantity}</Typography>
                  )}
                </Box>
              </TableCell>
              <TableCell align="right">
                <Typography variant="subtitle2" fontWeight="bold">
                  ¥{(item.curr_unit_price * item.quantity).toFixed(2)}
                </Typography>
              </TableCell>
              {/* 仅在订单状态为 'opened' 时显示删除按钮 */}
              {orderStatus === 'opened' && (
                <TableCell align="center">
                  <IconButton
                    color="error"
                    onClick={() => handleDeleteItem(item.id)}
                    size="small"
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              count={totalItems}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage="每页显示："
              rowsPerPageOptions={[5, 10, 15, 20, 50]}
              labelDisplayedRows={({ from, to, count }) =>
                `${from}-${to} 共 ${count} 项`
              }
              sx={{ borderBottom: 'none' }}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

export default OrderItems;
