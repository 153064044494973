import React from 'react';
import { Box } from '@mui/material';
import Logo from './logo.png'; // 确保 logo.png 文件路径正确

const Footer = () => {
  return (
    <Box 
      sx={{ 
        height: '60px', 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        backgroundColor: 'rgba(255, 255, 255, 0.7)', // 半透明白色背景
        backdropFilter: 'blur(10px)', // 模糊效果
        borderTop: '1px solid rgba(255, 255, 255, 0.3)', // 半透明边框
        boxShadow: '0px -4px 6px rgba(0, 0, 0, 0.1)', // 柔和阴影
        // 移除了 position: fixed 等样式
      }}
    >
      <img src={Logo} alt="Logo" style={{ height: '60px',padding: '10px' }} />
    </Box>
  );
};

export default Footer;
